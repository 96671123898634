// Dependencies
import React, {FC, useState} from 'react';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid, IconButton,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import { Remove } from '@mui/icons-material';

// Component
import { Dialog } from '../../../../components/Dialog'

// Styles
import styles from '../../styles';
import {LabelEditor} from '../../../../components/LabelEditor';

// Interfaces
interface IArrayEditModalProps {
  title: string;
  open: boolean;
  data: { label: string; value: string }[];
  loading: boolean;
  labelEditable?: boolean;
  onClose: () => void;
  onSave: (updatedData: object) => void;
}

// Export ArrayEdit Modal
export const ArrayEditModal: FC<IArrayEditModalProps> = ({
  title,
  open,
  data,
  loading,
  labelEditable,
  onClose,
  onSave,
}) => {
  const [dataArray, setDataArray] = useState<any[]>(data);
  console.log('dataArray???', dataArray);
  const initialValues = {};
  for (let i = 0; i < dataArray.length; i++) {
    if (!labelEditable) {
      // @ts-ignore
      initialValues[dataArray[i].label?.replace(/\./g, '__')] = dataArray[i].value;
    } else {
      if (dataArray[i]) {
        // @ts-ignore
        initialValues[dataArray[i].label?.replace(/\./g, '__')] = dataArray[i].label;
        if (dataArray[i].value?.includes && dataArray[i].value?.includes('###')) {
          const values = dataArray[i].value.split('###');
          values.map((v, index) => {
            initialValues['value_' + `i_${index}_` + dataArray[i].label?.replace(/\./g, '__')] = v;
          });
        } else {
          // @ts-ignore
          initialValues['value_' + dataArray[i].label?.replace(/\./g, '__')] = dataArray[i].value;
        }
      }

    }
  }

  // Create form hook
  const { values, handleChange, submitForm, setValues } = useFormik({
    initialValues,
    onSubmit: (values: any) => {
      if (!labelEditable) {
        onSave(
          dataArray.map((item) => ({
            ...item,
            value: values[item?.label.replace(/\./g, '__')],
          }))
        );
      } else {
        onSave(
          dataArray.map((item) => ({
            ...item,
            label: values[item?.label.replace(/\./g, '__')],
            value: item.value.includes && item.value.includes('###')
              ? item.value.split('###').map((v, index) => values['value_' + `i_${index}_` + item?.label.replace(/\./g, '__')]).join('###')
              : values['value_' + item?.label.replace(/\./g, '__')],
          }))
        );
      }
    },
  });

  const handleAddRow = () => {
    if (dataArray[0].value.includes && dataArray[0].value.includes('###')) {
      const length = dataArray[0].value.split('###');
      const key = `item__${+new Date()}`;
      const valueKey = 'value_' + `i_${length}_` + key;
      setValues({ ...values, [key]: '', [valueKey]: '' });
      setDataArray([...dataArray, { label: key, value: '' }]);
    } else {
      const key = `item__${+new Date()}`;
      const valueKey = 'value_' + key;
      setValues({ ...values, [key]: '', [valueKey]: '' });
      setDataArray([...dataArray, { label: key, value: '' }]);
    }
  };

  const handleRemoveItem = (index: number) => {
    setDataArray([...dataArray.filter((_, i) => i !== index)]);
  };

  // Return ArrayEdit Modal
  return (
    <Dialog open={open} onClose={onClose} sx={styles.dialog}>
      <DialogTitle sx={{ textTransform: 'uppercase' }}>{title.replace(/_/g, ' ')}</DialogTitle>
      <DialogContent sx={{ maxWidth: '600px', maxHeight: '500px' }}>
        <Box pt={20} sx={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
          {!labelEditable &&
            dataArray.map(({ label }, index) => (
              <Box key={index}>
                <TextField
                  fullWidth
                  name={label.replace(/\./g, '__')}
                  label={label}
                  /* @ts-ignore */
                  value={values[label.replace(/\./g, '__')]}
                  onChange={handleChange}
                />
              </Box>
            ))}
          {labelEditable &&
            dataArray.map(({ label, value }, index) => (
              <Box sx={{ display: "flex", items: "center", gap: 20 }}>
                <IconButton
                  sx={{ height: 16, width: 16, mt: 12 }}
                  onClick={() => handleRemoveItem(index)}
                >
                  <Remove />
                </IconButton>
                <Box key={index} sx={{ minWidth: 250 }}>
                  <TextField
                    fullWidth
                    name={label.replace(/\./g, '__')}
                    label="Label"
                    /* @ts-ignore */
                    value={values[label.replace(/\./g, '__')]}
                    onChange={handleChange}
                  />
                </Box>
                <Box key={index} sx={{ minWidth: 250 }}>
                  {(value?.includes && value.includes('###')) ? value.split('###').map((v, index) => (
                    <Box key={index}>
                      <TextField
                        fullWidth
                        name={'value_' + `i_${index}_` + label.replace(/\./g, '__')}
                        label="Value"
                        /* @ts-ignore */
                        value={values['value_' + `i_${index}_` + label.replace(/\./g, '__')]}
                        onChange={handleChange}
                      />
                    </Box>
                  )) : (
                    <TextField
                      fullWidth
                      name={'value_' + label.replace(/\./g, '__')}
                      label="Value"
                      /* @ts-ignore */
                      value={values['value_' + label.replace(/\./g, '__')]}
                      onChange={handleChange}
                    />
                  )}
                </Box>
              </Box>
            ))}
          {labelEditable && (
            <Box display="flex" mt={20}>
              <Button onClick={ handleAddRow } sx={{ ml: "auto", width: '200px' }} variant="outlined">+ Add</Button>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={submitForm}>{loading ? <CircularProgress size={20} /> : 'Save'}</Button>
      </DialogActions>
    </Dialog>
  );
};
