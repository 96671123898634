// Dependencies
import { FC, useMemo } from 'react';
import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import {ITemplate} from '../../../../resource';

// Interfaces
interface IChartData {
  label: string;
  value: number;
  color?: string;
}

export type ChartType = 'area' | 'bar' | 'donut';

interface IChartProps {
  data: IChartData[];
  type: ChartType | 'horizontal-bar';
  name?: string;
  template?: ITemplate,
}

// Default chart options
const defaultOptions: (data: IChartData[], type?: ChartType | 'horizontal-bar') => Record<ChartType, ApexOptions> = (data, type) => ({
  bar: {
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 0,
      colors: ['transparent'],
    },
    xaxis: {
      categories: data.map(({ label }) => label),
    },
    fill: {
      opacity: 1,
    },
    colors: ['#003F7A'],
  },
  'horizontal-bar': {
    legend: {
      show: true,
      position: 'left',
      fontSize: '20px',
      fontFamily: 'Fira Sans',
      itemMargin: {
        vertical: 10,
      },
      markers: {
        radius: 0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        columnWidth: '50%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 0,
      colors: ['transparent'],
    },
    xaxis: {
      categories: data.map(item => item.label),
    },
    yaxis: {
      show: true,
    },
    fill: {
      opacity: 1,
    },
  },
  area: {
    annotations: {
      yaxis: [
        {
          y: 30,
          borderColor: '#003F7A',
          label: {
            text: 'Support',
            style: {
              color: '#fff',
              background: '#00E396',
            },
          },
        },
      ],
      xaxis: [
        {
          borderColor: '#003F7A',
          label: {
            text: 'Rally',
            style: {
              color: '#fff',
              background: '#003F7A',
            },
          },
        },
      ],
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    xaxis: {
      categories: data.map(({ label }) => label),
    },
    yaxis: {
      labels: {
        formatter: (value: number) => value.toFixed(0),
      },
    },
    tooltip: {
      x: {
        format: 'MMM dd yyyy',
      },
    },
    colors: ['#003F7A'],
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
  },
  donut: {
    labels: data.map(({ label }) => label),
    colors: data.map(({ color }) => (color ? color : '#003F7A')),
    legend: {
      show: true,
      position: 'left',
      fontSize: '20px',
      fontFamily: 'Fira Sans',
      itemMargin: {
        vertical: 10,
      },
      markers: {
        radius: 0,
      },
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          legend: {
            position: 'bottom',
            width: '100%',
          },
        },
      },
    ],
  },
});

// Export chart
export const Chart: FC<IChartProps> = ({ data = [], name, type, template }) => {
  // Get chart series form data
  const series: ApexOptions['series'] = useMemo(() => {
    switch (type) {
      case 'bar': {
        const chartData = data?.map((item) => {
          let value: any = item.value;
          if (typeof value === 'string') {
            value = value.includes('###') ? value.split('###') : value;
          }

          return {
            ...item,
            value: value
          };
        });
        let datasets: any[] = [];
        if (chartData && Array.isArray(chartData?.[0]?.value)) {
          datasets = chartData?.[0]?.value.map((item, index) => {
            return {
              data: chartData.map((group) => group.value[index]),
              color: [template?.color || '#003F7A', '#667a7a'][index % 2]
            };
          });
        } else {
          datasets = [
            {
              data: chartData?.map((item) => item.value) || [31.0, -7.59],
              color: template?.color || '#003F7A'
            }
          ];
        }

        return datasets;
      }

      case 'horizontal-bar': {
        const datasets = [
          {
            data: data?.map((item) => item.value),
            color: template?.color
          }
        ];
        return datasets;
      }

      case 'area': {
        return [{ name, data: data.map(({ value }) => value) }];
      }

      case 'donut':
      default: {
        return data.map(({ value }) => value ? +value : 0);
      }
    }
  }, [data, type, template]);

  // Get chart option
  const options = useMemo(() => defaultOptions(data, type), [data, type]);

  // Return chart
  return <ReactApexChart options={options[type]} series={series} type={type === 'horizontal-bar' ? 'bar' : type} height={400} />;
};
